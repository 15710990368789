import { NavItemBlokProps } from '../components/NxPage/NxMenu';
import { PowerProTypes } from '../components/NxPage/NxPage';

/*
Please don't change the names of these two navigation links in global_navi in storyblok or the consequences will be catastrophic  
*/

export const DESIGN_MY_SYSTEM_TEXT = 'Digital Storefront';
export const LEARNING_CENTER_TEXT = 'Knowledge Base';

const isProd =
  typeof window !== 'undefined' &&
  window.location.origin.indexOf('swellenergy.com') > -1 &&
  window.location.origin.indexOf('sbx') === -1;

export const getPowerProFields = (sbxFieldName, prodFieldName) =>
  isProd ? prodFieldName : sbxFieldName;

export const DEFAULT_DIGITAL_STOREFRONT_URL = isProd
  ? 'https://shop.ige.swellenergy.com/swell'
  : 'https://shop.sbx.ige.swellenergy.com/swell';

export const DEFAULT_LEARNING_CENTER_URL = isProd
  ? 'https://shop.ige.swellenergy.com/swell/knowledge-base'
  : 'https://shop.sbx.ige.swellenergy.com/swell/knowledge-base';

interface Props extends PowerProTypes {
  navItems: NavItemBlokProps[];
}

export const navItemsWithPowerProItems = (props: Props) => {
  const {
    show_design_my_system,
    design_my_system_url,
    sbx_show_design_my_system,
    sbx_design_my_system_url,
    show_learning_center,
    learning_center_url,
    sbx_show_learning_center,
    sbx_learning_center_url,
    navItems,
  } = props;

  let _navItems = [...navItems];
  _navItems = _navItems.filter((navItem) => {
    if (navItem.component === 'nav_item') return Boolean(navItem.enabled); // only returning enabled nav_items
    return navItem; // this is spacer
  });

  _navItems = _navItems.map((navItem) => ({
    ...navItem,
    link: getPowerProFields(navItem.sbx_link, navItem.link),
  }));

  _navItems = _navItems.map((nav) => {
    if (nav.name === DESIGN_MY_SYSTEM_TEXT) {
      const url =
        getPowerProFields(sbx_design_my_system_url, design_my_system_url)?.url ||
        (isProd ? nav.link?.url : nav.sbx_link?.url) ||
        DEFAULT_DIGITAL_STOREFRONT_URL;
      return {
        ...nav,
        link: url,
        enabled: nav.enabled && isProd ? show_design_my_system : sbx_show_design_my_system,
      };
    }
    return nav;
  });

  _navItems = _navItems.map((nav) => {
    if (nav.name === LEARNING_CENTER_TEXT) {
      const url =
        getPowerProFields(sbx_learning_center_url, learning_center_url)?.url ||
        (isProd ? nav.link?.url : nav.sbx_link?.url) ||
        DEFAULT_LEARNING_CENTER_URL;
      return {
        ...nav,
        link: url,
        enabled: nav.enabled && (isProd ? show_learning_center : sbx_show_learning_center),
      };
    }
    return nav;
  });

  return _navItems;
};

export const getFooterPowerProAndLearningCenter = (
  props: Props,
  navItemName: typeof DESIGN_MY_SYSTEM_TEXT | typeof LEARNING_CENTER_TEXT
): { enabled?: boolean; link?: string } => {
  const {
    show_design_my_system,
    design_my_system_url,
    sbx_show_design_my_system,
    sbx_design_my_system_url,
    show_learning_center,
    learning_center_url,
    sbx_show_learning_center,
    sbx_learning_center_url,
    navItems,
  } = props;
  const _navItems = [...navItems];

  const field = _navItems.find((navItem) => navItem.name === navItemName);

  if (!field) return;

  if (navItemName.trim() === DESIGN_MY_SYSTEM_TEXT) {
    const url =
      getPowerProFields(sbx_design_my_system_url, design_my_system_url)?.url ||
      (isProd ? field.link?.url : field.sbx_link?.url) ||
      DEFAULT_DIGITAL_STOREFRONT_URL;
    return {
      ...field,
      enabled: field?.enabled && (isProd ? show_design_my_system : sbx_show_design_my_system),
      link: url,
    };
  }

  if (navItemName.trim() === LEARNING_CENTER_TEXT) {
    const url =
      getPowerProFields(sbx_learning_center_url, learning_center_url)?.url ||
      (isProd ? field.link?.url : field.sbx_link?.url) ||
      DEFAULT_LEARNING_CENTER_URL;
    return {
      ...field,
      enabled: field?.enabled && (isProd ? show_learning_center : sbx_show_learning_center),
      link: url,
    };
  }

  return { ...field, link: isProd ? field.link.url : field.sbx_link.url };
};
