import { useFormik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import { Search } from 'react-feather';
import SbEditable from 'storyblok-react';
import tw, { styled } from 'twin.macro';
import * as Yup from 'yup';

import { useEnvironment } from '../EnvironmentContext';
import { NxBaseProps } from '../NxBaseProps';
import { getComponentsForBlokArray } from '../NxComponents';

import { CircleLoader } from './CircleLoader';
import { Map } from './Map';
import {
  BlokProps,
  ContextValuesType,
  FinancialProductEnum,
  SystemDesignResponse,
} from './PowerProTypes';

const Wrapper = tw.section`px-4 mx-auto max-w-[60rem] my-[10rem] flex flex-col items-center pb-[8rem]`;
const TitleWrapper = tw.div`mb-8 text-[#211F1D]`;
const TinyTextWrapper = tw.div`mt-4 text-[#211F1D]`;
const FormWrapper = tw.form`w-full md:w-[28rem]`;
const ButtonWrapper = tw.div`mt-6`;
const SubmitButton = tw.button`w-full bg-[#053873] disabled:bg-[#6988aa] text-[20pt] text-white py-2 rounded-2xl disabled:!shadow-none disabled:text-[20pt]`;
const AddressInput = styled.input(({ active }: { active: boolean }) => [
  active && tw`border-[#053873]`,
  tw` placeholder:text-xs md:placeholder:text-lg border-[#50749C] border focus:border-[#053873] text-[#053873] placeholder:text-[#50749C] focus:border-2  rounded-2xl py-6 w-[calc(100% - 96px)] md:w-full px-4 outline-none pl-10 md:pl-12 `,
]);
const SearchIcon = styled(Search)(({ active }: { active: boolean }) => [
  tw`absolute left-4 text-[#50749C] w-[18px] md:w-[24px]`,
  active && tw`text-[#053873]`,
]);
const Flex = tw.div`flex items-center relative`;
const ErrorMessage = tw.div`text-red-500 text-xs mt-2 text-center`;

const validationSchema = Yup.object({
  address: Yup.string()
    .required('Address is a required field.')
    .test({
      name: 'streetAddress',
      exclusive: false,
      params: {},
      message: 'Please select a valid address.',
      test: (value, context) =>
        !!context.parent.streetAddress &&
        value?.includes(context.parent.streetNumber) &&
        value?.includes(context.parent.zip) &&
        value?.includes(context.parent.state) &&
        value?.includes(context.parent.city) &&
        value?.includes(context.parent.streetAddress) &&
        context.parent.country === 'US' &&
        value !== '',
    }),
  buildingArea: Yup.number(),
  lat: Yup.string().required(),
  lng: Yup.string().required(),
  streetAddress: Yup.string().required(),
  placeId: Yup.string().required(),
  zip: Yup.string(),
  country: Yup.string(),
});

const initialValues = {
  address: '',
  lat: 0,
  lng: 0,
  streetAddress: '',
  placeId: '',
  zip: '',
  streetNumber: '',
  state: '',
  city: '',
  country: '',
};

type FormData = typeof initialValues;

const percent = 72;

export default function PowerPro(props: NxBaseProps<BlokProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { environment } = useEnvironment();

  const urlEnv = environment === 'prod' ? 'prod' : 'sbx';

  const ref = useRef();

  const {
    blok: { title, disclaimer_text, subtext, link },
  } = props;

  const storefrontSlug = useMemo(() => {
    const splitUrl = link?.url?.split('/');
    let slug: string;
    if (splitUrl.includes('recommended-system')) slug = splitUrl[splitUrl.length - 2];
    else slug = splitUrl[splitUrl.length - 1];
    return slug;
  }, [link.url]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values: FormData, formikHelper) => {
      setIsLoading(true);

      try {
        const {
          address,
          streetAddress,
          lat,
          lng,
          placeId,
          zip,
          streetNumber,
          state,
          city,
          country,
        } = values;

        fetch(`https://api.shop.${urlEnv}.ige.swellenergy.com/system-design`, {
          method: 'POST',
          body: JSON.stringify({
            addressString: address,
            lat,
            lng,
            storefrontSlug,
            autopanelPlaceId: placeId,
            productSelection: '3',
            financialProduct: FinancialProductEnum.cash,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((res) => res.json())
          .then((calculationResult) => {
            if (calculationResult.message) {
              return setErrorMessage(calculationResult.message);
            }

            const {
              annualEnergyConsumptionKwh,
              availableFinancialProducts,
              details,
              autopanelJobId,
              lseId,
            }: SystemDesignResponse = calculationResult;
            setErrorMessage('');

            const calculatedFinanceType = availableFinancialProducts?.includes(
              FinancialProductEnum.esa
            )
              ? FinancialProductEnum.esa
              : FinancialProductEnum.cash;

            const defaultSystemValuesFromDetails = {
              panelInverterCount: details.systemDetails.panel.quantity,
              batteryCount: details.systemDetails.battery.quantity,
            };

            const valuesToSend: Partial<ContextValuesType> = {
              annualEnergyConsumptionKwh,
              address,
              lat,
              lng,
              streetAddress,
              placeId,
              zip,
              autopanelJobId,
              addressString: address,
              financeType: calculatedFinanceType,
              lseId,
              streetNumber,
              state,
              city,
              country,
              defaultSystemValues: defaultSystemValuesFromDetails,
              selectedBatteryQty: defaultSystemValuesFromDetails?.batteryCount,
              selectedPanelQty: defaultSystemValuesFromDetails?.panelInverterCount,
              userSelectedPanelQty: defaultSystemValuesFromDetails?.panelInverterCount,
              userSelectedBatteryQty: defaultSystemValuesFromDetails?.batteryCount,
            };

            const queryParams = {
              data: JSON.stringify(valuesToSend),
            };

            const queryString = new URLSearchParams(queryParams).toString();
            window.open(`${link.url}?${queryString}`);
          })
          .catch((err) => console.log({ err }));
      } catch (error) {
        console.log({ error });
      } finally {
        formikHelper.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  return (
    <SbEditable content={props.blok}>
      <Wrapper>
        <TitleWrapper>{!!title && getComponentsForBlokArray(title)}</TitleWrapper>
        <TitleWrapper>{!!subtext && getComponentsForBlokArray(subtext)}</TitleWrapper>

        <FormWrapper onSubmit={formik.handleSubmit}>
          <Map formik={formik} inputRef={ref} />
          <Flex>
            <AddressInput
              ref={ref}
              onKeyDown={formik.setFieldValue.bind('streetAddress', '')}
              placeholder="Please enter property address"
              id="address"
              name="address"
              active={!!formik.values.address}
              {...formik.getFieldProps('address')}
              onChange={(e) => {
                if (errorMessage) setErrorMessage('');
                if (!e.target.value) formik.setFieldValue('streetAddress', e.target.value);
                formik.setFieldValue('address', e.target.value);
              }}
            />
            <SearchIcon active={!!formik.values.address} />

            <CircleLoader
              percent={formik.values.address && formik.values.streetAddress ? percent : 0}
            />
          </Flex>
          {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <ButtonWrapper>
            <SubmitButton
              style={{ boxShadow: '8px 10px 22px -9px rgba(5,56,115,0.75)' }}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || isLoading}
            >
              Design my system
            </SubmitButton>
          </ButtonWrapper>
        </FormWrapper>

        <TinyTextWrapper>
          {!!disclaimer_text && getComponentsForBlokArray(disclaimer_text)}
        </TinyTextWrapper>
      </Wrapper>
    </SbEditable>
  );
}
